import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const TimePage = ({location}) => {
	/*
	 * Passing Time Parameter via URL
	 * possible/expected routes:
	 *    <origin>/time/?<whatever>=<time string>
	 *    <origin>/time?<whatever>=<time string>
	 */
	const reGoodDateFormat = /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\d[-+]\d\d:\d\d$/m;
	let {href: uri} = location;

	if(uri) uri = uri.trimEnd();
	try{
		uri = decodeURI(uri)
		// console.log('decodeURI has yielded: ' + uri);
	}catch(e){
		// console.log('failed to decodeURI: ' + e);
		uri = undefined;
	}

	const aResult = uri && reGoodDateFormat.exec(uri);
	const strGoodDate = aResult && aResult[0]; //console.log('strGoodDate is: ' + strGoodDate);
	const msGoodDate = Date.parse(strGoodDate); //console.log('msGoodDate is: ' + msGoodDate);
	const dateGood = !isNaN(msGoodDate) && new Date(msGoodDate);

	const iOffset = -(new Date()).getTimezoneOffset()/60;
	let strTimeZone;
	try{
		strTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	}catch(e){
	}

	const localTime = (new Date()).toLocaleString();
	const localTimeZone = '' + strTimeZone;
	const localOffset = 'UTC' + (iOffset >= 0 ? '+' + iOffset : iOffset);
	const receivedTime = dateGood ? dateGood.toLocaleString() : null;
	const numDay = dateGood ? dateGood.getDate() : null;
	const numMonth = dateGood ? (dateGood.getMonth() + 1) : null;

	return (
		<Layout>
			<Seo title="Time" />
			<h2>Time</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/rebecca1.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>
			<h5>
				You've been communicated the following time, translated to <span className="italic">your</span> locale:
			</h5>
			<div>
				{dateGood ? (
					<p>
						{receivedTime}
						<br />
						(day {numDay} of month {numMonth})
					</p>
				) : 'unable to parse provided date/time'}
			</div>
			<h5>
				You can trust that the translated time is correct if the following appears correct:
			</h5>
			<ul>
				<li>local time <span className="bold">now</span>: {localTime}</li>
				<li>local time zone: {localTimeZone}</li>
				<li>local offset: {localOffset}</li>
			</ul>
			<h5>
				Not correct? Try a different device/browser!
			</h5>

		</Layout>
	);
}

export default TimePage;
